@import 'src/styles/colors';

.status {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 2px 10px;
    height: 16px;
    border-radius: 5px;
    font-size: 8px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.50px;
    cursor: default;

    &.live {
        background: $statusLiveBg;
        color: $statusLive;
        padding-left: 15px;

        &:after {
            content: '•';
            position: absolute;
            top: 50%;
            left: 6px;
            transform: translateY(-50%);
        }
    }
    &.finished {
        background: $statusFinishedBg;
        color: $statusFinished;
    }
    &.notStarted {
        background: $statusNotStartedBg;
        color: $statusNotStarted;
    }
}
