@import 'src/styles/colors';

.hotEvents {
    position: relative;
    margin: 128px 0;
    z-index: 1;
}
.tabs {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
}
.titleWrapper {
    position: relative;
}

@media screen and (max-width: 768px) {
    .hotEvents {
        margin: 0;
    }
    .tabs {
        display: flex;
        justify-content: center;
        position: static;
        transform: none;
        margin-bottom: 30px;
    }
}
.eventWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;
  }
  
  .centeredItem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .matchItem {
    width: 100%;
    max-width: 800px; /* 📏 Увеличил максимальную ширину, чтобы карточка растянулась */
    min-width: 600px; /* 📏 Задаем минимальную ширину для лучшего отображения */
  }
  
  .getBonusWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
  }
  
  /* 📱 Мобильная адаптация */
  @media (max-width: 768px) {
    .matchItem {
      max-width: 100%;
      min-width: auto;
    }
  }
  