@import "./src/styles/colors";

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 35px;
    min-width: 100px;
    padding: 5px;
    border-radius: 17px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    text-decoration: none;
    cursor: pointer;
    overflow: hidden;
    transition: background-color .3s, color .3s, border .3s, box-shadow .3s;

    &.default {
        border: 2px solid $green;
        background: $greenGradient;
        color: $black;

        &:hover {
            border-color: $greenLight;
        }
    }
    &.secondary {
        border: 2px solid $blue;
        background-color: $blue;
        color: $white;

        &:hover {
            background-color: $blueLight;
            border-color: $blueLight;
        }
    }
    &.main {
        height: 56px;
        padding: 14px 40px;
        background: linear-gradient(94deg, #5F0FC3 0%, #00519B 100%);
        box-shadow: 0 0 16px 4px rgba(57.52, 155.79, 246.50, 0.32);
        border-radius: 56px;
        border: 2px #459CED solid;
        color: $white;
        font-size: 14px;
        font-weight: 700;

        &:hover {
            background: linear-gradient(94deg, #5F0FC3 0%, #00519B 100%);
            box-shadow: 0 0 32px 4px rgba(57.52, 155.79, 246.50, 0.64);
        }
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -100%;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent);
            animation: shinyAnimation 3s infinite;
        }
    }
    &.blur {
        color: $white;
        font-size: 14px;
        font-weight: 700;
        height: 44px;
        padding: 12px 24px;
        background: rgba(5.10, 5.10, 5.10, 0.08);
        border-radius: 40px;
        border: 2px #646464 solid;
        box-shadow: inset 0 0 5px #646464;
        backdrop-filter: blur(4px);

        &:hover {
            border-color: #459CED;
            box-shadow: inset 0 0 10px #459CED;
        }
    }
    &.big {
        min-height: 40px;
        min-width: 120px;
        border-radius: 22px;
    }
}
.icon {
    width: 24px;
    height: 24px;

    &.left {
        order: 1;
        margin-right: 8px;
    }
    &.right {
        margin-left: 8px;
    }
    img {
        max-width: 100%;
        max-height: 100%;
    }
}


@keyframes shinyAnimation {
    0% {
        left: -100%;
    }
    50% {
        left: 100%;
    }
    100% {
        left: 100%;
    }
}
