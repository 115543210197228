@import 'src/styles/colors';

.jackpotEvents {
    position: relative;
    margin: 128px 0 128px -4px;
    z-index: 1;

    :global {
        .swiper {
            padding: 2px 4px 10px 4px;
        }
        .swiper-pagination {
            position: static;
            margin-top: 24px;
        }
        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
            opacity: 1;
            background: transparent;
            border-color: #515050;

            &:after {
                color: #515050;
            }
        }
        .swiper-button-prev,
        .swiper-button-next {
            width: 40px;
            height: 40px;
            top: auto;
            bottom: 0;
            background: linear-gradient(90deg, #3F3F3F 0%, black 100%);
            box-shadow: 0 0 0 rgba(0, 0, 0, 0);
            border-radius: 24px;
            border: 1px #515050 solid;
        }
        .swiper-button-prev {
            right: auto;
            left: calc(50% - 80px);
            transform: translate(-50%, 0);
        }
        .swiper-button-next {
            left: auto;
            right: calc(50% - 115px);
            transform: translate(-50%, 0);
        }
        .swiper-button-prev:after,
        .swiper-button-next:after {
            font-size: 12px;
            color: #B0F320;
        }
    }
}
.tabs {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
}
.titleWrapper {
    position: relative;
}
.pagination {
    color: $white;
    font-size: 16px;
    font-weight: 700;

    span {
        font-size: 12px;
        color: $white;
        margin: 0 4px;
    }
}

@media screen and (max-width: 768px) {
    .jackpotEvents {
        margin: 0;
    }
    .tabs {
        display: flex;
        justify-content: center;
        position: static;
        transform: none;
        margin-bottom: 30px;
    }
}

/* Добавляем стили для текста в блоке с описанием */
.description {
    color: #ffffff;  /* Белый цвет для текста */
    background-color: rgba(0, 0, 0, 0.7);  /* Темный полупрозрачный фон */
    padding: 16px;
    border-radius: 8px;
    margin-top: 20px;

    h3 {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 16px;
    }

    p {
        font-size: 16px;
        line-height: 1.5;
    }

    /* Дополнительные стили для мобильных устройств */
    @media screen and (max-width: 768px) {
        padding: 12px;
        h3 {
            font-size: 20px;
        }
        p {
            font-size: 14px;
        }
    }
}
/* Стили для картинок */
.image {
    width: 100%;  /* Ширина картинки на 100% от контейнера */
    height: auto; /* Высота автоматически по пропорциям */
    // max-width: 350px; /* Максимальная ширина 300px */
    max-height: 450px; /* Максимальная ширина 300px */
    object-fit: cover; /* Обтягивание, чтобы картинка сохраняла пропорции */
    border-radius: 8px; /* Радиус скругления углов */
    margin: 0 auto; /* Центрирование */
  }
  
.button {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  padding: 12px 0;
  background-color: #ffcc00;
  color: #000;
  font-weight: bold;
  text-decoration: none;
  border-radius: 8px;
  transition: background 0.3s;

  &:hover {
    background-color: #ffdd44;
  }
}
