@import 'src/styles/colors';

.allEvents {
    position: relative;
    background-color: #080C15;
    background-position-y: calc(100% + 1px);
    background-repeat: no-repeat;
    background-size: cover;

    &:before,
    &:after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 150px;
        pointer-events: none;
    }
    &:before {
        top: 0;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    }
    &:after {
        bottom: 0;
        background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    }
    &.mobile {
        background-size: contain;
    }
}
.allEventsInside {
    padding-bottom: 100px;
}
.tabs {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.titleWrapper {
    position: relative;
}
.items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    min-height: 300px;
}
.itemWrapper {
    padding: 0 10px;
    flex: 0 0 33%;
    max-width: 33%;
    margin-bottom: 20px;
}
.showMoreButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

@media screen and (max-width: 1200px) {
    .itemWrapper {
        flex: 0 0 50%;
    }
}
@media screen and (max-width: 768px) {
    .allEventsInside {
        padding-bottom: 80px;
    }
    .tabs {
        display: flex;
        justify-content: center;
        position: static;
        transform: none;
        margin-bottom: 30px;
    }
    .itemWrapper {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
.subscribeButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
  }
  