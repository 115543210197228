@import 'src/styles/colors';

.modalPortal {
    :global {
        .ui-modal-portal__content {
            width: 500px;
            padding: 80px;
            background: #050505;
            box-shadow:
                    0 0 300px 150px rgba(0, 0, 0, 1),
                    inset 0 0.3em 0.3em rgba(15, 53, 222, 0.7);
            border-radius: 24px;
            border: 1px solid #465f0f;
        }
    }
}
.modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.image {
    max-width: 100%;
}
.title {
    color: $white;
    font-size: 32px;
    font-weight: 700;
    margin-top: 32px;
}
.description {
    color: #D6D6D6;
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
    margin-bottom: 32px;
}

@media screen and (max-width: 768px) {
    .modalPortal {
        :global {
            .ui-modal-portal__content {
                padding: 80px 16px;
            }
        }
    }
}
