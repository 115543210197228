@import 'src/styles/colors';

.modal {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  backdrop-filter: blur(2px);
  display: flex;
  padding: 16px;
  overflow-x: hidden;
  overflow-y: auto;
}
.content {
  display: flex;
  min-height: 168px;
  border-radius: 12px;
  flex-direction: column;
  background: $white;
  padding: 32px;
  position: relative;
  z-index: 1;
  max-width: 100%;
  margin: auto;

  :global {
    .ui-close-button {
      position: absolute;
      z-index: 1;
      top: 16px;
      right: 16px;
      transition: color .2s ease;
    }
  }
}
.title {
  font-size: 24px;
  font-weight: 700;
  color: $black;
  margin-bottom: 20px;
}
