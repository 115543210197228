@import 'src/styles/colors';

.tabs {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin: 0 -5px;
}
.tabWrapper {
    padding: 0 5px;
}
.tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 7px 8px 12px;
    background: $tabBg;
    border: 1px solid #343434;
    border-radius: 24px;
    font-size: 8px;
    font-weight: 700;
    text-transform: uppercase;
    user-select: none;
    cursor: pointer;

    &.active {
        background: $greenGradient;
        border-color: #B0F320;
        cursor: default;
        pointer-events: none;

        .title {
            color: #173400;
        }
    }
    &.disabled {
        background: $tabDisable;
        border-color: #343434;
        cursor: default;
        pointer-events: none;

        .title,
        .count{
            color: #515050;
        }
    }
}
.title {
    font-weight: 700;
    font-size: 12px;
    color: #D6D6D6;
    white-space: nowrap;
}
.count {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $black;
    color: $white;
    border-radius: 10px;
    padding: 2px 4px;
    min-width: 18px;
    height: 18px;
    font-weight: 700;
    font-size: 11px;
    margin-left: 4px;
}


