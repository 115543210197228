.wrapper {
    position: relative;
}

:global {
    .horizontal-menu {
        &__arrow-wrapper {
            position: absolute;
            width: 32px;
            height: 41px;
            top: 50%;
            transform: translateY(-50%);
            color: var(--draggerScrollButtonArrow);
            background: var(--draggerScrollButtonBg);
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            font-size: 14px;
            z-index: 1;
            opacity: 0;
            transition: opacity .15s ease;

            &:disabled {
                display: none;
            }
            &--left {
                left: 0;

                .svg-icon {
                    transform: rotate(180deg);
                }
            }
            &--right {
                right: 0;

                .svg-icon {
                    transform: rotate(0deg);
                }
            }
        }
        .react-horizontal-scrolling-menu--wrapper {
            position: relative;

            &:hover {
                .horizontal-menu {
                    &__arrow-wrapper {
                        opacity: 1;

                        &--disabled {
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }

    .react-horizontal-scrolling-menu--scroll-container {
        overscroll-behavior: contain;
    }

    .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
        display: none;
    }

    .react-horizontal-scrolling-menu--scroll-container {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    .horizontal-menu__arrow-wrapper {
        border: none;
    }
    .horizontal-menu__arrow-wrapper--left {
        background: linear-gradient(to right, rgba(000,000,000, 1), rgba(000,000,000, 0));
    }
    .horizontal-menu__arrow-wrapper--right {
        background: linear-gradient(to left, rgba(000,000,000, 1), rgba(000,000,000, 0));
    }
}

