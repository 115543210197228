// .subscribeButton {
//     display: inline-block;
//     margin-top: 15px;
//     padding: 12px 24px;
//     background: linear-gradient(90deg, #ffcc00 0%, #ffaa00 100%);
//     color: #000;
//     font-weight: bold;
//     text-transform: uppercase;
//     text-align: center;
//     border-radius: 8px;
//     transition: background 0.3s, transform 0.2s;
//     cursor: pointer;
//     text-decoration: none;
//   }
  
//   .subscribeButton:hover {
//     background: linear-gradient(90deg, #ffdb4d 0%, #ffb300 100%);
//     transform: scale(1.05);
//   }
.subscribeButton {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 56px;
    padding: 14px 40px;
    background: linear-gradient(94deg, #5F0FC3 0%, #00519B 100%);
    box-shadow: 0 0 16px 4px rgba(57, 155, 246, 0.32);
    border-radius: 56px;
    border: 2px #459CED solid;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;
    transition: background 0.3s, box-shadow 0.3s, transform 0.2s;
    gap: 5px; /* Расстояние между текстом и иконкой */

    &:hover {
        background: linear-gradient(94deg, #5F0FC3 0%, #00519B 100%);
        box-shadow: 0 0 32px 4px rgba(57, 155, 246, 0.64);
        transform: scale(1.05);
    }
}

.iconRight {
    width: 34px;
    height: 34px;
}
