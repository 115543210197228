$black: #000000;
$white: #ffffff;
$secondary: #171717;

$green: #40811d;
$greenLight: #CDFF7B;
$greenGradient: linear-gradient(180deg, #CDFF7B 0%, #257300 100%);

$blue: #3647D2;
$blueLight: #475AEF;

$gold: #FFE300;
$goldDull: rgba(255, 227, 0, .5);

$red: #C00303;
$grayLight: #A4B2C9;

$tabShadow: rgba(33, 35, 37, 0.5);
$tabBg: linear-gradient(180deg, #3F3F3F 0%, black 100%);
$tabDisable: #0e0e0f;

$roadmapBg: #151617;
$roadmapSubTitleTextShadow: #226003;

$statusLiveBg: linear-gradient(100deg, #DC5252 0%, #CF1D1D 100%);
$statusLive: #FEECEC;
$statusFinishedBg: linear-gradient(100deg, #1C1C1C 0%, #0F0F0F 100%);
$statusFinished: #6F6F6F;
$statusNotStartedBg: linear-gradient(100deg, #B0F320 0%, #679406 100%);
$statusNotStarted: #2E2E2E;
