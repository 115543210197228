@import 'src/styles/colors';

.itemWrapper {
    position: relative;

    &.statusLive {
        .lineTop {
            background: linear-gradient(97deg, #DC5252 0%, #CF1D1D 100%);
        }
    }
    &.statusNotStarted {
        .lineTop {
            background: linear-gradient(97deg, #B0F320 0%, #679406 100%);
        }
    }
    &.statusLive,
    &.statusNotStarted {
        .item {
            &.hot,
            &.all {
                box-shadow: 0 2px 8px 0.5px rgba(176, 243, 32, 0.32);
            }
            &.gold {
                box-shadow: 0 2px 8px 0.5px rgba(238, 232, 88, 0.32);
            }
            &.jackpot {
                box-shadow: 0 2px 8px 0.5px rgba(242, 192, 15, 0.32);
            }
        }
    }
    &.statusFinished {
        .item {
            filter: grayscale(30%);
            box-shadow: 0 2px 8px 0.5px rgba(177.44, 177.44, 177.44, 0.32);
        }
        .lineTop {
            background: #80858C;
        }
    }

    &:hover {
        .getBonus {
            opacity: 1;
        }
        .lineTop {
            opacity: 0;
        }
    }
}
.item {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 260px;
    border-radius: 10px;
    filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.9));
    background-size: cover;
    background-position: center;
    z-index: 1;

    &.hot {
        &.statusLive,
        &.statusNotStarted {
            box-shadow: 0 0 10px rgba(176, 243, 32, .5);
        }
    }
    &.all {
        box-shadow: 0 8px 16px rgba(7.76, 12.75, 6.96, 0.56);
    }
    &.gold {
        .labelWrapper {
            &:after {
                background-color: #EEE858;
            }
        }
        .label {
            background: #EEE858;
        }
    }
    &.jackpot {
        .labelWrapper {
            &:after {
                background-color: #F2C00F;
            }
        }
        .label {
            background: #F2C00F;
        }
    }
}
.lineTop {
    position: absolute;
    top: -2px;
    width: 100%;
    height: 20px;
    border-radius: 10px 10px 0 0;
}
.content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: linear-gradient(180deg, $black 0%, rgba(0, 0, 0, .1) 100%);
    border-radius: 10px 10px 0 0;
    z-index: 1;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
}
.headerLeft,
.headerRight {
    display: flex;
}
.headerLeft {
    align-items: center;
}
.leagueImgWrapper {
    width: 35px;
    height: 35px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: $white;
    text-align: center;

    img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 50%;
    }
}
.leagueInfo {
    display: flex;
    flex-direction: column;
}
.leagueName {
    margin-bottom: 4px;
    color: $white;
}
.leagueContry {
    display: flex;
    align-items: center;
}
.leagueContryImgWrapper {
    width: 16px;
    height: 16px;
    margin-right: 5px;

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: fill;
    }
}
.leagueContryName {
    font-weight: 700;
    font-size: 8px;
    text-transform: uppercase;
    color: #80858C;
}
.headerRight {
    flex-direction: column;
    align-items: flex-end;
}
.date {
    font-weight: 700;
    font-size: 12px;
    color: $white;
}
.status {
    margin-top: 6px;
}
.body {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-grow: 1;
}
.bodyInside {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 24px 16px;
    width: 100%;
}
.bodyInsideLeft {
    max-width: 80%;
}
.bodyInsideLeft,
.bodyInsideRight {
    display: flex;
    align-items: center;
}
.teams {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;

    .team:first-child {
        margin-bottom: -10px;
        z-index: 1;
    }
}
.team {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 100%;
}
.teamName {
    font-weight: 700;
    font-size: 16px;
    max-height: 40px;
    color: $white;
    margin-left: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.teamImgWrapper {
    width: 40px;
    min-width: 40px;
    height: 40px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 1);
    background-color: $white;
    text-align: center;
    border-radius: 50%;

    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: fill;
        text-align: center;
    }
}
.scoreMatchWrapper {
    display: flex;
    flex-direction: column;
    width: 40px;
    height: 65px;
    border-radius: 5px;
    color: $white;
    font-size: 16px;
    font-weight: 700;
    background: linear-gradient(180deg, rgba(62.69, 62.69, 62.69, 0.01) 0%, rgba(0, 0, 0, 0.01) 100%);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border: 1px #7F8278 solid;
    backdrop-filter: blur(32px);
}
.scoreMatch {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}
.scoreMatchLine {
    width: 100%;
    height: 1px;
    background-color: #7F8278;
}
.labelWrapper {
    display: flex;
    justify-content: center;

    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: calc(100% - 1px);

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: #B0F320;
    }
}
.label {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    padding: 5px 16px;
    height: 20px;
    font-weight: 700;
    font-size: 8px;
    text-transform: uppercase;
    color: #2E2E2E;
    background: #B0F320;
    border-radius: 5px 5px 0 0;
}
.footer {
    display: flex;
    position: relative;
    height: 40px;
    background: rgba(27.63, 27.63, 27.63, 0.90);
    border-radius: 0 0 10px 10px;
}
.footerItem {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    color: #E4E4E4;
    font-size: 16px;
    font-weight: 700;
    flex: 0 0 33%;

    &:nth-child(2) {
        border-left: 1px solid rgba(255, 255, 255, .1);
        border-right: 1px solid rgba(255, 255, 255, .1);
    }
}

.getBonus {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.56);
    backdrop-filter: blur(35px);
    border-radius: 10px;
    z-index: 1;
    opacity: 0;
    transition:
            background .3s ease-in-out 0s,
            backdrop-filter .3s ease-in-out 0s,
            opacity .3s ease-in-out 0s;
}
