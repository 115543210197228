@import 'src/styles/colors';

.goldEvents {
    position: relative;
    margin: 128px 0;
    z-index: 1;
}
.tabs {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
}
.titleWrapper {
    position: relative;
}

@media screen and (max-width: 768px) {
    .goldEvents {
        margin: 0;
    }
    .tabs {
        display: flex;
        justify-content: center;
        position: static;
        transform: none;
        margin-bottom: 30px;
    }
}
.cardsWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); 
    gap: 3px; /* 📏 Уменьшил отступ между карточками */
    justify-content: center;
    margin-top: 20px;
  }
  
  .eventCard {
    position: relative;
    width: 100%;
    max-width: 399px;
    height: 240px;
    border-radius: 15px;
    background: url('../../../assets/match/events-gold-bg.png') center/cover no-repeat;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
  }
  
  .eventCard:hover {
    transform: scale(1.05);
  }
  
  .eventCardBlur {
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(12px);
    background: rgba(0, 0, 0, 0.4);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: backdrop-filter 0.3s ease-in-out, background 0.3s ease-in-out;
  }
  
  .eventCard:hover .eventCardBlur {
    backdrop-filter: blur(18px);
    background: rgba(0, 0, 0, 0.6);
  }
  
  .subscribeButton {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    max-width: 220px;
    text-align: center;
  }
  
  /* 📱 Мобильная адаптация */
  @media (max-width: 768px) {
    .cardsWrapper {
      grid-template-columns: 1fr;
      gap: 8px; /* 📏 Еще меньше отступ на мобилках */
    }
  
    .eventCard {
      max-width: 100%;
      height: 200px;
    }
  
    .subscribeButton {
      bottom: 15px;
      width: 90%;
    }
  }
  