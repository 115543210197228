@import 'src/styles/colors';

.leagueContainerWrapper {
    position: relative;
    margin-bottom: 32px;
    padding-left: 60px;

    &.isMobile {
        margin-bottom: 24px;
    }
}
.leagueContainer {
    position: relative;
}
.leagueWrapper {
    display: flex;
    align-items: center;
}
.leagueTitle {
    color: $white;
    font-size: 12px;
    margin-right: 8px;
    white-space: nowrap;
}
.league {
    display: flex;
    align-items: center;
    height: 28px;
    padding: 4px 8px;
    background-color: #1F1F1F;
    border-radius: 24px;
    margin-right: 16px;
    border: none;
    cursor: pointer;

    &.all {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);

        .leagueName {
            color: $white;
        }
    }
    &.active {
        .leagueName {
            color: #B0F320;
        }
        .leagueCount {
            color: #050505;
            background-color: #B0F320;
        }
    }
}
.leagueIcon {
    width: 16px;
    height: 16px;
    background-color: $white;
    border-radius: 50%;
}
.leagueName {
    color: #515050;
    font-size: 12px;
    white-space: nowrap;
    margin: 0 8px;
}
.leagueCount {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    height: 16px;
    color: #515050;
    font-size: 8px;
    background-color: #050505;
    border-radius: 50%;
    font-weight: 700;
}
