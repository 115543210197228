@import "./src/styles/colors";

.footer {
    background-color: $black;
}
.footerInside {
    display: flex;
    align-items: center;
    min-height: 70px;
}
.left,
.center,
.right {
    display: flex;
    align-items: center;
    flex: 0 0 33%;
}
.center {
    justify-content: center;
}
.right {
    justify-content: flex-end;
}
.siteRight {
    font-weight: 400;
    font-size: 12px;
    color: #515050;
}

@media screen and (max-width: 992px) {
    .footerInside {
        flex-direction: column;
        justify-content: center;
        padding: 15px;
    }
    .social {
        margin-bottom: 10px;
    }
    .left,
    .right {
        justify-content: center;
    }
}
