@import 'src/styles/colors';

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    padding: 10px 0 64px 0;
    overflow: hidden;
}
.starsBg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    pointer-events: none;
}
.ballWrapper {
    position: relative;
    width: 320px;
    height: 320px;
    margin: 80px 0;
}
.ball {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 320px;
    height: 320px;
    max-width: 100%;
    max-height: 100%;
}
.logo {
    position: absolute;
    left: 50%;
    top: 10px;
    transform: translate(-50%, 0);
}
.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    z-index: 1;
}
.labelWrapper {
    display: inline-flex;
    padding: 8px 24px;
    background-color: $secondary;
    border-radius: 24px;
    font-size: 16px;
    color: $white;
}
.labelText {
    font-family: monospace;
    white-space: nowrap;
    overflow: hidden;
    border-right: 1px solid white;
    animation: typing 0.8s steps(40, end), blink-caret 0.5s step-end infinite, cursor 0.8s step-end infinite;
}
@keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}
@keyframes blink-caret {
    from,
    to {
        border-color: transparent;
    }
    50% {
        border-color: #000;
    }
}
@keyframes cursor {
    0% {
        border-color: white;
    }
    50% {
        border-color: transparent;
    }
    100% {
        border-color: white;
    }
}

.subscribeButton {
    display: inline-block;
    margin-top: 15px;
    padding: 12px 24px;
    background: linear-gradient(90deg, #ffcc00 0%, #ffaa00 100%);
    color: #000;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    border-radius: 8px;
    transition: background 0.3s, transform 0.2s;
    cursor: pointer;
    text-decoration: none;
  }
  
  .subscribeButton:hover {
    background: linear-gradient(90deg, #ffdb4d 0%, #ffb300 100%);
    transform: scale(1.05);
  }
  .container {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .imageWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1; // Отправляем фон на задний план
  }
  
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover; // Заполняем всю область
  }
  
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 2;
  }
  
  .labelWrapper {
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    padding: 10px 20px;
    border-radius: 20px;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 20px;
  }

// @import 'src/styles/colors';

// .container {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     position: relative;
//     padding: 10px 0 64px 0;
//     overflow: hidden;
//     height: 100vh;
//     width: 100vw;
// }

// /* Фон */
// .imageWrapper {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     z-index: 0; /* Фон уходит на задний план */
// }

// .image {
//     width: 100%;
//     height: 100%;
//     object-fit: cover; /* Картинка растягивается на весь экран */
// }

// /* Контент */
// .content {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     text-align: center;
//     position: relative;
//     z-index: 2;
//     padding-top: 20px; /* Поднимаем текст */
// }

// /* Заголовок */
// .labelWrapper {
//     background: rgba(0, 0, 0, 0.6);
//     color: #fff;
//     padding: 12px 24px;
//     border-radius: 30px;
//     font-weight: bold;
//     font-size: 28px;
//     margin-bottom: 360px; /* Увеличиваем расстояние от заголовка до кнопки */
// }

// /* Кнопка подписки */
// .subscribeButton {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     position: relative;
//     height: 56px;
//     padding: 14px 40px;
//     background: linear-gradient(94deg, #5F0FC3 0%, #00519B 100%);
//     box-shadow: 0 0 16px 4px rgba(57, 155, 246, 0.32);
//     border-radius: 56px;
//     border: 2px #459CED solid;
//     color: #ffffff;
//     font-size: 14px;
//     font-weight: 700;
//     text-transform: uppercase;
//     cursor: pointer;
//     text-decoration: none;
//     transition: background 0.3s, box-shadow 0.3s, transform 0.2s;
//     margin-top: 20px; /* Опускаем кнопку немного вниз */
// }

// .subscribeButton:hover {
//     background: linear-gradient(94deg, #5F0FC3 0%, #00519B 100%);
//     box-shadow: 0 0 32px 4px rgba(57, 155, 246, 0.64);
//     transform: scale(1.05);
// }
