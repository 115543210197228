@import 'src/styles/colors';

.titleWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    height: 36px;
}
.title {
    font-size: 24px;
    font-weight: 700;
    color: $white;
    line-height: 20px;
}
.count {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    height: 32px;
    min-width: 40px;
    padding: 4px 8px;
    color: $white;
    background: $black;
    box-shadow: inset 0 0 5px $white;
    border-radius: 80px;
    pointer-events: none;
}

@media screen and (max-width: 992px) {
    .titleWrapper {
        margin-bottom: 15px;
    }
}
