@import '../assets/font/stylesheet.scss';
@import 'colors.scss';

* {
    padding: 0;
    margin: 0;
    list-style: none;
    outline: none;
    font-family: 'Roboto', system-ui, Tahoma, sans-serif;
    box-sizing: border-box;
}

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: $black;
}
html,
body {
    min-height: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;

    &.portal-active {
        overflow: hidden !important;
    }
}
html {
    scrollbar-color: rgba($secondary, 0.4) transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 16px;
        height: 16px;
        background-color: rgba($secondary, 0.2);
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 4px solid transparent;
        background-clip: content-box;
        background-color: rgba($secondary, 1);
    }
}
a,
span,
p,
b,
h1,
h2,
h3,
h4,
h5 {
    color: $black;
}

h1 {
    font-size: 48px;
}

h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 30px;
}

a {
    text-decoration: none;
}

.container {
    max-width: 1280px;
    margin: auto;
}

.scrollbar {
    scrollbar-color: rgba($secondary, 0.4) transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 16px;
        height: 16px;
        background-color: rgba($secondary, 0.2);
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 4px solid transparent;
        background-clip: content-box;
        background-color: rgba($secondary, 1);
    }
}

.matches-items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    @media screen and (max-width: 992px) {
        flex-wrap: nowrap;
        padding-top: 2px;
        margin: 0 -10px 0 0;
        overflow-x: auto;

        .matches-item:not(:last-child) {
            margin-right: 16px;
        }
    }

    &--one-item {
        @media screen and (max-width: 992px) {
            margin: 0;

            .matches-item {
                flex: 1;
                max-width: none;

                &:not(:last-child) {
                    margin-right: 0;
                }
            }
        }
    }
}
.matches-item {
    flex: 1;
    max-width: 395px;
    padding: 0 10px;
    margin-bottom: 20px;

    @media screen and (max-width: 992px) {
        flex: 0 0 340px;
        max-width: 340px;
        padding: 0;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 992px) {
    .container {
        margin: 0 10px;
    }
}

@mixin noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome, Opera and Firefox */
}
